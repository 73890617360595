<template>
  <l-map
    v-if="bound.length > 0"
    :style="{ height: height }"
    :zoom="zoom"
    :center="center"
    :bounds="bounds"
  >
    <l-tile-layer
      :url="url"
      :attribution="attribution"
    />
    <l-control-fullscreen
      position="topright"
      :options="{ title: { false: 'Full Screen', true: 'Dashboard View' } }"
    />

    <!--  -->

    <slot name="markers">
      <!-- <l-marker :lat-lng="markerLatLng" /> -->
    </slot>

    <slot name="polyline">
      <l-polyline
        :lat-lngs="polyline.latlngs"
        :color="polyline.color"
      />
    </slot>
  </l-map>
</template>

<script>
import { latLngBounds } from "leaflet";
import LControlFullscreen from "vue2-leaflet-fullscreen";
import "leaflet-fullscreen/dist/leaflet.fullscreen.css";
import { LMap, LTileLayer, LPolyline } from "vue2-leaflet";

export default {
  components: {
    LMap,
    LTileLayer,
    // LMarker,
    LPolyline,
    //
    LControlFullscreen,
  },
  props: {
    height: {
      type: String,
      default() {
        return "300px";
      },
    },
    bound: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 15,
      center: [47.31322, -1.319482],
      markerLatLng: [],
      polyline: {
        latlngs: [
          [47.334852, -1.509485],
          [47.342596, -1.328731],
          [47.241487, -1.190568],
          [47.234787, -1.358337],
        ],
        color: "green",
      },
    };
  },
  computed: {
    bounds() {
      return latLngBounds(this.bound);
    },
  },
};
</script>
